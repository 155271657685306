/**
 * @fileoverview JSS styles for Verification.js
 */
import { siteTheme } from "../../themes";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
  callToAction: {
    margin: "1em",

    /* Resize the call out button based on screen size */
    [siteTheme.breakpoints.between("xs", "md")]: {
      fontSize: "1em",
    },
    [siteTheme.breakpoints.up("md")]: {
      fontSize: "2em",
      padding: "0.2em 1em",
    },
  },
});

export default styles;
