/**
 * @fileoverview JSS styles for Account.js
 */

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  deleteAccountButton: {
    color: "red",
    borderColor: "red",
  },
});

export default styles;
