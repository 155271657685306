/**
 * @fileoverview React Component that displays when a user first visits the site
 */

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import clsx from "clsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";

import CasinoIcon from "@material-ui/icons/Casino";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ScheduleIcon from "@material-ui/icons/Schedule";

import styles from "./Landing.jss";

import AnnouncementsCard from "../AnnouncementsCard/AnnouncementsCard";
import ContactCard from "../ContactCard/ContactCard";
import SignUp from "../SignUp/SignUp";

import * as selectors from "../../store/selectors";
import { signUp } from "../../store/actions";

/**
 * Landing page for AGWSU
 * @implements { Component }
 */
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpDialogOpen: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * Called when props for component update
   * @param {*} prevProps previous props for class
   */
  componentDidUpdate(prevProps) {
    //on props.loggedIn change from falsey to truthy, close signup dialog if open
    if (!prevProps.loggedIn && this.props.loggedIn) {
      this.handleCloseSignUpDialog();
    }
  }

  /**
   * Handles the SignUp Dialog open action. Sets local state.
   */
  handleOpenSignUpDialog = () => {
    this.setState({ signUpDialogOpen: true });
  };

  /**
   * Handles the SignUp Dialog close action. Sets local state.
   */
  handleCloseSignUpDialog = () => {
    this.setState({ signUpDialogOpen: false });
  };

  handleSendVerificationEmail = () => {
    this.props.sendVerificationEmail();
  };

  handleSignUp = (name, email, password) => {
    this.props.signUp(name, email, password);
  };

  render() {
    const { approved, classes, fullScreenDialogs, loggedIn, rejected } =
      this.props;
    const { signUpDialogOpen } = this.state;

    return (
      <React.Fragment>
        <section className={classes.parallax}>
          <div className={classes.title}>
            <Typography variant="h2" inline="true">
              Wright State University&nbsp;
            </Typography>
            <Typography variant="h2" inline="true">
              Adventurers' Guild
            </Typography>
          </div>
          {!loggedIn && (
            <Button
              className={classes.callToAction}
              variant="contained"
              color="primary"
              onClick={this.handleOpenSignUpDialog}
            >
              SIGN UP
            </Button>
          )}
          {loggedIn && !approved && !rejected && (
            <Button
              className={classes.callToAction}
              variant="contained"
              color="primary"
            >
              AWAITING ACCOUNT APROVAL
            </Button>
          )}
          {loggedIn && rejected && (
            <Button
              className={classes.callToAction}
              variant="contained"
              color="primary"
            >
              ACCOUNT APPLICATION REJECTED
            </Button>
          )}
          <SignUp
            fullScreen={fullScreenDialogs}
            handleClose={this.handleCloseSignUpDialog}
            handleSignUp={this.handleSignUp}
            open={signUpDialogOpen}
          />
        </section>
        <section
          href="info"
          className={clsx(classes.section, classes.sectionInfo)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AnnouncementsCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <Link
                    href="https://goo.gl/maps/AFVCZ6MgsKEndUJR8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemIcon>
                      <LocationOnIcon fontSize="large" />
                    </ListItemIcon>
                  </Link>
                  <ListItemText
                    primary="WSU, Oleman Hall 3rd Floor"
                    primaryTypographyProps={{ variant: "h3" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ScheduleIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText
                    primary="6:30pm - Midnight Every Friday"
                    primaryTypographyProps={{ variant: "h3" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CasinoIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Tabletop, Board, Card, & Video Games"
                    primaryTypographyProps={{ variant: "h3" }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EmojiEmotionsIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Friendly Atmosphere"
                    primaryTypographyProps={{ variant: "h3" }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6} className={classes.infoSide}></Grid>
          </Grid>
        </section>
        <section
          href="contact"
          className={clsx(classes.section, classes.sectionContact)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <ContactCard
                name="Example Name"
                title="President"
                email="test@example.com"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ContactCard
                name="Example Name"
                title="Treasurer"
                email="test@example.com"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ContactCard
                name="Example Name"
                title="Faculty Advisor"
                email="test@example.com"
              />
            </Grid>
          </Grid>
        </section>
      </React.Fragment>
    );
  }
}

Landing.propTypes = {
  fullScreenDialogs: PropTypes.bool,
};

Landing.defaultProps = {
  fullScreenDialogs: false,
};

const mapStateToProps = (state) => ({
  loggedIn: selectors.isLoggedIn(state),
  approved: selectors.isApproved(state),
  rejected: selectors.isRejected(state),
});

const mapDispatchToProps = (dispatch) => ({
  signUp: bindActionCreators(signUp, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Landing));
