/**
 * @fileoverview JSS styles for AnnouncementsDashboard.js
 */

const styles = (theme) => ({
  announcementCard: {
    margin: "1em 0",
    padding: "1em",
  },
  cardActions: {
    paddingTop: "1em",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    zIndex: 2,
  },
  maxAgeSlider: {
    maxWidth: "90%",
    margin: "2em 0",
  },
});

export default styles;
