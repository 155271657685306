/**
 * @fileoverview React Component that displays annoucements in a horizontally swipeable card format
 */

import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import { connect } from "react-redux";

import { Button, Card, MobileStepper, withStyles } from "@material-ui/core";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import * as selectors from "../../store/selectors";
import styles from "./AnnouncementsCard.jss";
import Postcard from "../Postcard/Postcard";

/**
 * React Component that displays annoucements in a horizontally swipeable card format
 * @implements { Component }
 */
class AnnouncementsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
    };
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleStepChange = (step) => {
    this.setState({ activeStep: step });
  };

  render() {
    const { announcements } = this.props;
    const { activeStep } = this.state;

    return (
      <Card>
        <SwipeableViews
          axis={"x"}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
        >
          {announcements.map((announcement, index) => (
            <Postcard
              key={index}
              content={announcement.content}
              date={announcement.date.toDate()}
              expanded={index === activeStep}
              pin={announcement.pin}
              dispOnly={true}
              title={announcement.title}
              uid={announcement.id}
            />
          ))}
        </SwipeableViews>

        <MobileStepper
          steps={announcements.length}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep >= announcements.length - 1}
            >
              Older
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep <= 0}
            >
              <KeyboardArrowLeft />
              Newer
            </Button>
          }
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  announcements: selectors.getFrontPageAnnouncements(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AnnouncementsCard));
