/**
 * @fileoverview JSS styles for Footer.js
 */

import { siteTheme } from "../../themes";

const styles = (theme) => ({
  devAvatar: {
    width: siteTheme.spacing(5),
    height: siteTheme.spacing(5),
  },
  footer: {
    padding: "1em 2em",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footerRight: {
    width: "10em",
  },
  xsStack: {
    display: "flex",
    alignItems: "center",
    [siteTheme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    [siteTheme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
});

export default styles;
