/**
 * @fileoverview state selectors. Use selectors instead of referencing state itself.
 */
import { createSelector } from "reselect";

const oneWeekInMilliseconds = 604800000;
export const getUserMessages = (state) => state.user.messages;
export const getDisplayName = (state) =>
  state.user.displayNames[state.user.displayNames.length - 1];
export const getDisplayNames = (state) => state.user.displayNames;
export const getEmail = (state) =>
  state.user.emails[state.user.emails.length - 1];
export const getEmails = (state) => state.user.emails;
export const isPendingUserActions = (state) => state.user.pendingActions > 0;
export const hasFailedLogin = (state) => state.user.failedLogin;
export const hasFailedSignUp = (state) => state.user.failedSignUp;
export const isLoggedIn = (state) => state.user.loggedIn;
export const isEmailVerified = (state) => state.user.emailVerified;
export const isApproved = (state) => state.user.approved;
export const isRejected = (state) => state.user.rejected;
export const isAdmin = (state) => state.user.admin;

export const isPendingAnnouncementsAction = (state) =>
  state.announcements.pendingAction;
export const getAnnouncements = (state) =>
  state.announcements.announcements.sort((a, b) => (a.date > b.date ? -1 : 1));
export const getAnnouncementsMessages = (state) => state.announcements.messages;
export const getAnnouncementsMaxLandingWeeks = (state) =>
  state.announcements.announcementsConfig.maxLandingWeeks
    ? state.announcements.announcementsConfig.maxLandingWeeks
    : 2;

export const getUnapprovedUsers = (state) =>
  state.users.users.filter((user) => !user.approved && !user.rejected);

export const getUser = (uid) => {
  return createSelector(getUsers, (users) =>
    users.filter((user) => user.uid === uid)
  );
};

export const getUsers = (state) => state.users.users;

/**
 * Gets sorted Announcements array for the landing page where the date is between now and the max age (set in config) or is pinned.
 * @param {Object} state
 */
export const getFrontPageAnnouncements = createSelector(
  [getAnnouncements, getAnnouncementsMaxLandingWeeks],
  (announcements, maxLandingWeeks) => {
    let now = Date.now();
    let maxAge = now - oneWeekInMilliseconds * maxLandingWeeks;
    return announcements
      .filter(
        (announcement) =>
          (announcement.date.toDate() < now &&
            announcement.date.toDate() > maxAge) ||
          announcement.pin
      )
      .sort(
        (a, b) =>
          (a.pin ? a.pin : false) === (b.pin ? b.pin : false) //For each pin value in sort, we must check that the value exists, otherwise return false for the value
            ? a.date > b.date //XNOR on pinned, sort on date
              ? -1
              : 1
            : (a.pin ? a.pin : false) * -1 + (b.pin ? b.pin : false) * 1 //XOR on pinned, sort on pinned
      );
  }
);

export const getUtilMessages = (state) => state.util.messages;

export const isPendingActions = createSelector(
  [isPendingUserActions, isPendingAnnouncementsAction],
  (pendingUserAction, pendingAnnouncementsAction) => {
    return pendingUserAction || pendingAnnouncementsAction;
  }
);
