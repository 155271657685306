/**
 * @fileoverview Methods for interfacing with firebase authentication
 * @exports { doSignInWithEmailAndPassword, doSignOut, authObserver }
 */

import firebase from "firebase/app";
import { auth } from "./firebaseInit";
import * as firebaseUsers from "./firebaseUsers";
import store from "../store/store";
import { loginChanged } from "../store/actions";

/**
 * Attempts login to firebase
 * @param {*} email Email of the user attempting to login
 * @param {*} password Password of the user attempting login
 * @returns {Promise} Promise object represents async login call to firebase
 */
export const doSignInWithEmailAndPassword = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};

/**
 * Attempts logout from firebase
 * @returns {Promise} Promise object represents async logout call to firebase
 */
export const doSignOut = () => {
  return auth.signOut();
};

/**
 * Creates a user in the firebase authentication system with the email and password given
 * @param {string} email
 * @param {string} password
 * @returns {Promise}
 */
export const doCreateUserWithEmailAndPassword = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const doDeleteCurrentUser = (password) => {
  return reauthenticate(password).then(() => auth.currentUser.delete());
};

/**
 * Updates the profile for the current user
 * @param {string} name
 * @param {string} photoURL
 */
export const doUpdateCurrentUserProfile = (name, photoURL = undefined) => {
  if (photoURL === undefined) {
    return auth.currentUser.updateProfile({
      displayName: name,
    });
  }
  return auth.currentUser.updateProfile({
    displayName: name,
    photoURL: photoURL,
  });
};

/**
 * Sends an email verification to current user
 */
export const doSendVerificationEmail = () => {
  return auth.currentUser.sendEmailVerification();
};

export const doSendPasswordResetEmail = (email) => {
  return auth.sendPasswordResetEmail(email);
};

export const reauthenticate = (password) => {
  let cred = firebase.auth.EmailAuthProvider.credential(
    auth.currentUser.email,
    password
  );
  return auth.currentUser.reauthenticateWithCredential(cred);
};

export const doUpdateEmail = (email, password) => {
  return reauthenticate(password).then(() =>
    auth.currentUser.verifyBeforeUpdateEmail(email)
  );
};

export const doUpdatePassword = (oldPassword, newPassword) => {
  return reauthenticate(oldPassword).then(() =>
    auth.currentUser.updatePassword(newPassword)
  );
};

/**
 * Gets user ActionCodeInfo
 * @param {*} oobCode from url query parameter
 */
export const doGetActionCodeInfo = (oobCode) => {
  return auth.checkActionCode(oobCode);
};

/**
 * Changes password with actionCode
 * @param {*} oobCode from url query parameter
 * @param {*} newPassword new password for account
 */
export const doPasswordReset = (oobCode, newPassword) => {
  return auth.confirmPasswordReset(oobCode, newPassword);
};

/**
 * Verifies email with actionCode
 * @param {*} oobCode from url query parameter
 */
export const doVerifyEmail = (oobCode) => {
  return auth.applyActionCode(oobCode);
};

/**
 * Listener for firebase authentication change
 */
export const authObserver = auth.onAuthStateChanged((user) => {
  if (user) {
    firebaseUsers.attachCurrentUserListener();
    store.dispatch(
      loginChanged(user.displayName, user.email, user.emailVerified, user.uid)
    );
  } else {
    firebaseUsers.detachCurrentUserListener();
    store.dispatch(loginChanged());
  }
});
