/**
 * @fileoverview Reducer for any user actions
 */
import * as actions from "../constants/actionTypes";

const actionPending = (state, action) => ({
  ...state,
  pendingActions: state.pendingActions + 1,
});

const actionFulfilled = (state, action) => ({
  ...state,
  pendingActions: state.pendingActions - 1,
});

const actionRejected = (state, action) => ({
  ...state,
  pendingActions: state.pendingActions - 1,
  messages: [...state.messages, action.payload.message],
});

const passwordResetEmailSent = (state, action) => ({
  ...state,
  pendingActions: state.pendingActions - 1,
  messages: [...state.messages, "Password Reset Email Sent, Check Your Inbox"],
});

const passwordResetFulfilled = (state, action) => ({
  ...state,
  pendingActions: state.pendingActions - 1,
  messages: [...state.messages, "Password Reset, Please Login"],
});

const loginFulfilled = (state, action) => ({
  ...actionFulfilled(state, action),
  failedLogin: false,
});

const signupFulfilled = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Registration Complete"],
});

const loginRejected = (state, action) => ({
  ...actionRejected(state, action),
  failedLogin: true,
  messages: [...state.messages, action.payload.message],
});

const currentUserDbChanged = (state, action) => ({
  ...state,
  admin: action.payload.admin,
  approved: action.payload.approved,
  displayNames: action.payload.displayNames,
  emails: action.payload.emails,
  emailVerified: action.payload.emailVerified,
  rejected: action.payload.rejected,
});

const loginChanged = (state, action) => ({
  ...state,
  loggedIn: action.payload.uid !== null,
  emailVerified: action.payload.emailVerified,
  uid: action.payload.uid,
});

const emailUpdated = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [
    ...state.messages,
    "Email Change Verification Sent to New Email Address",
  ],
});

const passwordUpdated = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Password Changed"],
});

const gotEmailFromActionCode = (state, action) => ({
  ...actionFulfilled(state, action),
  oobEmail: action.payload,
});

const sendVerificationEmail = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Verification Email Sent"],
});

const displayNameUpdated = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Display Name Updated"],
});

const emailVerified = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Email Verified, Thanks!"],
});

const userRequestResubmitted = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Approval Request Resubmitted"],
});

let handlers = {};
handlers[actions.CURRENT_USER_DB_CHANGED] = currentUserDbChanged;
handlers[actions.LOGIN_CHANGED] = loginChanged;
handlers[actions.pendingAction(actions.LOGIN)] = actionPending;
handlers[actions.pendingAction(actions.LOGOUT)] = actionPending;
handlers[actions.pendingAction(actions.UPDATE_CURRENT_USER)] = actionPending;
handlers[actions.pendingAction(actions.SEND_VERIFICATION_EMAIL)] =
  actionPending;
handlers[actions.pendingAction(actions.SEND_PASSWORD_RESET_EMAIL)] =
  actionPending;
handlers[actions.pendingAction(actions.SIGNUP)] = actionPending;
handlers[actions.pendingAction(actions.UPDATE_EMAIL)] = actionPending;
handlers[actions.pendingAction(actions.UPDATE_DISPLAY_NAME)] = actionPending;
handlers[actions.pendingAction(actions.UPDATE_PASSWORD)] = actionPending;
handlers[actions.pendingAction(actions.GET_EMAIL_FROM_ACTION_CODE)] =
  actionPending;
handlers[actions.pendingAction(actions.VERIFY_EMAIL)] = actionPending;
handlers[actions.pendingAction(actions.RESET_PASSWORD)] = actionPending;
handlers[actions.pendingAction(actions.RESUBMIT_USER_REQUEST)] = actionPending;
handlers[actions.pendingAction(actions.DELETE_CURRENT_USER)] = actionPending;

handlers[actions.fulfilledAction(actions.SIGNUP)] = signupFulfilled;
handlers[actions.fulfilledAction(actions.LOGIN)] = loginFulfilled;
handlers[actions.fulfilledAction(actions.LOGOUT)] = actionFulfilled;
handlers[actions.fulfilledAction(actions.UPDATE_CURRENT_USER)] =
  actionFulfilled;
handlers[actions.fulfilledAction(actions.SEND_VERIFICATION_EMAIL)] =
  sendVerificationEmail;
handlers[actions.fulfilledAction(actions.SEND_PASSWORD_RESET_EMAIL)] =
  passwordResetEmailSent;
handlers[actions.fulfilledAction(actions.UPDATE_EMAIL)] = emailUpdated;
handlers[actions.fulfilledAction(actions.UPDATE_DISPLAY_NAME)] =
  displayNameUpdated;
handlers[actions.fulfilledAction(actions.UPDATE_PASSWORD)] = passwordUpdated;
handlers[actions.fulfilledAction(actions.GET_EMAIL_FROM_ACTION_CODE)] =
  gotEmailFromActionCode;
handlers[actions.fulfilledAction(actions.VERIFY_EMAIL)] = emailVerified;
handlers[actions.fulfilledAction(actions.RESET_PASSWORD)] =
  passwordResetFulfilled;
handlers[actions.fulfilledAction(actions.RESUBMIT_USER_REQUEST)] =
  userRequestResubmitted;
handlers[actions.fulfilledAction(actions.DELETE_CURRENT_USER)] =
  actionFulfilled;

handlers[actions.rejectedAction(actions.SIGNUP)] = actionRejected;
handlers[actions.rejectedAction(actions.LOGIN)] = loginRejected;
handlers[actions.rejectedAction(actions.LOGOUT)] = actionRejected;
handlers[actions.rejectedAction(actions.UPDATE_CURRENT_USER)] = actionRejected;
handlers[actions.rejectedAction(actions.UPDATE_EMAIL)] = actionRejected;
handlers[actions.rejectedAction(actions.UPDATE_DISPLAY_NAME)] = actionRejected;
handlers[actions.rejectedAction(actions.UPDATE_PASSWORD)] = actionRejected;
handlers[actions.rejectedAction(actions.SEND_VERIFICATION_EMAIL)] =
  actionRejected;
handlers[actions.rejectedAction(actions.SEND_PASSWORD_RESET_EMAIL)] =
  actionRejected;
handlers[actions.rejectedAction(actions.GET_EMAIL_FROM_ACTION_CODE)] =
  actionRejected;
handlers[actions.rejectedAction(actions.VERIFY_EMAIL)] = actionRejected;
handlers[actions.rejectedAction(actions.RESET_PASSWORD)] = actionRejected;
handlers[actions.rejectedAction(actions.RESUBMIT_USER_REQUEST)] =
  actionRejected;
handlers[actions.rejectedAction(actions.DELETE_CURRENT_USER)] = actionRejected;

const userReducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export default userReducer;
