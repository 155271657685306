/**
 * @fileoverview Initalizes redux store for project
 */

import { applyMiddleware, createStore, compose } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import reducer from "./reducers/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(promise, thunk))
);

export default store;
