/**
 * @fileoverview JSS styles for ContactCard.js
 */

import { siteTheme } from "../../themes";

const styles = (theme) => ({
  contactCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "20em",
    margin: "auto",
  },
  contactItem: {
    margin: siteTheme.spacing(2),
  },
});

export default styles;
