/**
 * @fileoverview React Component that displays annoucements dashboard
 */

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Fab, Grid, Slider, Typography, withStyles } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

import {
  announcementCreate,
  announcementDelete,
  announcementEdit,
  postSnackbarMessage,
  updateMaxLandingWeeks,
} from "../../store/actions";
import * as selectors from "../../store/selectors";

import Postcard from "../Postcard/Postcard";

import styles from "./AnnouncementsDashboard.jss";
/**
 * React Component that displays announcements dashboard
 * @implements { Component }
 */
class AnnouncementsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backdropOpen: false,
      dirty: [],
      expanded: "",
    };
  }

  componentDidUpdate(prevProps) {
    // if edit or delete completed
    if (prevProps.pendingAction && !this.props.pendingAction) {
      this.setState({
        expanded: null,
      });
    }
  }

  handleCancel = (uid) => {
    this.setState({
      expanded: null,
    });
    this.props.postSnackbarMessage("Changes Discarded");
  };

  /**
   * Handles creation of new announcement
   */
  handleCreate = () => {
    this.props.announcementCreate();
  };

  /**
   * Handles deletion of announcement with id === uid
   * @param {string} uid
   */
  handleDelete = (uid) => {
    this.setState({
      confirmOpen: false,
    });
    this.props.announcementDelete(uid);
  };

  /**
   * Expands the announcement where id === uid
   * @param {string} uid
   */
  handleExpand = (uid) => {
    if (uid === this.state.expanded) {
      return;
    }
    if (
      this.state.dirty.some(
        (dirtyPostcard) => dirtyPostcard.uid === this.state.expanded
      )
    ) {
      this.setState({
        expanded: uid,
      });
      this.props.postSnackbarMessage(
        "Temporary draft saved.  Use the Save button to secure your changes save or Cancel to discard changes."
      );
    } else {
      this.setState({ expanded: uid });
    }
  };

  /**
   * Manages dirty changes to postcards
   * @param {Object} postcard { uid, keyBeingChanged}
   * @param {Boolean} dirtyFlag flag to set the postcard dirty or not
   * @example
   * // Removes object with uid "AAAA" from dirty array
   * handleSetDirty({uid: "AAAA"}, false)
   * @example
   * // Adds/Modifies object with uid "AAAA" in array: adds/overwrites key "title"
   * handleSetDirty({uid: "AAAA", title:"Dirty Test"}, true)
   */
  handleSetDirty = (postcard, dirtyFlag) => {
    //get all dirty postcards that are not changing
    let unchanged = this.state.dirty.filter(
      (dirtyPostcard) => dirtyPostcard.uid !== postcard.uid
    );

    if (dirtyFlag) {
      //find object with uid of incoming postcard, if it exists
      let toChange = this.state.dirty.find(
        (dirtyPostcard) => dirtyPostcard.uid === postcard.uid
      );
      toChange = { ...toChange, ...postcard };
      this.setState({
        dirty: [...unchanged, toChange],
      });
    } else {
      this.setState({
        dirty: unchanged,
      });
    }
  };

  /**
   * Handles saving edits to announcement where id === param.uid
   * @param {object} announcement
   */
  handleSave = (announcement) => {
    this.props.announcementEdit(
      announcement.uid,
      announcement.title,
      announcement.content,
      announcement.date,
      announcement.pin
    );
  };

  handleUpdateMaxLandingWeeks = (newValue) => {
    if (this.props.maxLandingWeeks === newValue) return;
    this.props.updateMaxLandingWeeks(newValue);
  };

  render() {
    const { announcements, classes, fullScreenDialogs, maxLandingWeeks } =
      this.props;
    const { dirty, expanded } = this.state;

    const marks = [
      {
        value: 1,
        label: "1 Week",
      },
      {
        value: 10,
        label: "10 Weeks",
      },
    ];

    return (
      <section>
        <Fab
          className={classes.fab}
          color="secondary"
          onClick={this.handleCreate}
        >
          <AddIcon />
        </Fab>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Landing Page</Typography>
            <hr />
            Do not display announcements older than:
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Slider
              defaultValue={2}
              onChange={(event, newValue) =>
                this.handleUpdateMaxLandingWeeks(newValue)
              }
              value={maxLandingWeeks}
              valueLabelDisplay="on"
              step={1}
              marks={marks}
              min={1}
              max={10}
              className={classes.maxAgeSlider}
            />
          </Grid>
          {announcements.map((announcement, index) => {
            let dirtyPostcard = dirty.find(
              (dirtyPostcard) => dirtyPostcard.uid === announcement.id
            );
            return (
              <Grid item xs={12} key={index}>
                <Postcard
                  content={
                    announcement.content
                      ? announcement.content
                      : "Default content."
                  }
                  date={
                    announcement.date ? announcement.date.toDate() : new Date()
                  }
                  expanded={announcement.id === expanded}
                  fullScreenDialogs={fullScreenDialogs}
                  pin={announcement.pin ? announcement.pin : false}
                  title={
                    announcement.title ? announcement.title : "Default Title"
                  }
                  uid={announcement.id}
                  dirtyContent={dirtyPostcard ? dirtyPostcard.content : null}
                  dirtyDate={dirtyPostcard ? dirtyPostcard.date : null}
                  dirtyPin={dirtyPostcard ? dirtyPostcard.pin : null}
                  dirtyTitle={dirtyPostcard ? dirtyPostcard.title : null}
                  cancel={this.handleCancel}
                  delete={this.handleDelete}
                  expand={this.handleExpand}
                  save={this.handleSave}
                  setDirty={this.handleSetDirty}
                />
              </Grid>
            );
          })}
        </Grid>
      </section>
    );
  }
}

AnnouncementsDashboard.propTypes = {
  fullScreenDialogs: PropTypes.bool,
};

AnnouncementsDashboard.defaultProps = {
  fullScreenDialogs: false,
};

const mapStateToProps = (state) => ({
  announcements: selectors.getAnnouncements(state),
  pendingAction: selectors.isPendingAnnouncementsAction(state),
  maxLandingWeeks: selectors.getAnnouncementsMaxLandingWeeks(state),
});

const mapDispatchToProps = (dispatch) => ({
  announcementCreate: bindActionCreators(announcementCreate, dispatch),
  announcementDelete: bindActionCreators(announcementDelete, dispatch),
  announcementEdit: bindActionCreators(announcementEdit, dispatch),
  postSnackbarMessage: bindActionCreators(postSnackbarMessage, dispatch),
  updateMaxLandingWeeks: bindActionCreators(updateMaxLandingWeeks, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AnnouncementsDashboard));
