/**
 * @fileoverview React Component that displays individual user requests
 */

import React, { Component } from "react";

import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import styles from "./UserRequestsCard.jss";

/**
 * React Component that displays individual user requests
 * @implements { Component }
 */
class UserRequestsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
    };
  }

  handleDialogConfirm = (response) => {
    this.setState({ confirmOpen: false });
    this.props.reject(this.props.uid, response);
  };

  handleDialogCancel = () => {
    this.setState({ confirmOpen: false });
  };

  handleReject = () => {
    this.setState({ confirmOpen: true });
  };

  render() {
    const { uid, displayName, email, classes, fullScreenDialogs, accept } =
      this.props;
    const { confirmOpen } = this.state;
    return (
      <React.Fragment>
        <ConfirmDialog
          cancelButtonText="Nevermind"
          confirmButtonText="Reject User"
          fullScreen={fullScreenDialogs}
          handleConfirm={this.handleDialogConfirm}
          handleCancel={this.handleDialogCancel}
          message="Enter a reason for the rejection (Optional)"
          open={confirmOpen}
          textArea={true}
          textAreaLabel="Rejection Reason"
          title="Really Reject User?"
        />
        <Card>
          <CardContent>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <Typography variant="h5">{displayName}</Typography>
              <Typography variant="h5">{email}</Typography>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<ClearIcon />}
                onClick={() => this.handleReject(uid)}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<CheckIcon />}
                onClick={() => accept(uid)}
              >
                Accept
              </Button>
            </Grid>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }
}

UserRequestsCard.propTypes = {
  fullScreenDialogs: PropTypes.bool,
  uid: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  accept: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
};

UserRequestsCard.defaultProps = {
  fullScreenDialogs: false,
  uid: "",
  displayName: "Adventure Kid",
  email: "new@wsu.edu",
};

export default withStyles(styles)(UserRequestsCard);
