/**
 * @fileoverview React Component that displays editable expandable card for posting messages. Card supports markdown previews.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  withStyles,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import { DatePicker } from "@material-ui/pickers";

import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

import styles from "./Postcard.jss";

/**
 * React Component that displays editable expandable card for posting messages. Card supports markdown previews.
 * @implements { Component }
 */
class Postcard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
      writeMode: !this.props.dispOnly,
      content: this.props.content,
      date: this.props.date,
      pin: this.props.pin,
      title: this.props.title,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uid !== this.props.uid) {
      this.setState({
        content: this.props.dirtyContent
          ? this.props.dirtyContent
          : this.props.content,
        date: this.props.dirtyDate ? this.props.dirtyDate : this.props.date,
        pin: this.props.dirtyPin != null ? this.props.dirtyPin : this.props.pin,
        title: this.props.dirtyTitle ? this.props.dirtyTitle : this.props.title,
      });
    }
  }

  handleCancel = () => {
    this.setState({
      content: this.props.content,
      date: this.props.date,
      pin: this.props.pin,
      title: this.props.title,
    });
    this.props.cancel(this.props.uid);
    this.props.setDirty({ uid: this.props.uid }, false);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
    this.props.setDirty(
      {
        [key]: value,
        uid: this.props.uid,
      },
      true
    );
  };

  handleConfirmClose = () => {
    this.setState({ confirmOpen: false });
  };

  handleConfirmDelete = () => {
    this.setState({ confirmOpen: false });
    this.props.setDirty({ uid: this.props.uid }, false);
    this.props.delete(this.props.uid);
  };

  handleDateChange = (newDate) => {
    newDate.setHours(0);
    newDate.setMinutes(1);
    this.handleChange("date", newDate);
  };

  handleDelete = () => {
    this.setState({ confirmOpen: true });
  };

  handleSave = () => {
    this.props.save({
      uid: this.props.uid,
      title: this.state.title,
      pin: this.state.pin,
      content: this.state.content,
      date: this.state.date,
    });
    this.props.setDirty({ uid: this.props.uid }, false);
  };

  /**
   * Sets the mode to write or preview
   * @param {Boolean} mode
   */
  setWriteMode = (mode) => {
    this.setState({ writeMode: mode });
  };

  render() {
    const {
      classes,
      dateOptions,
      expanded,
      fullScreenDialogs,
      uid,
      expand,
      dispOnly,
    } = this.props;
    const { confirmOpen, content, date, pin, title, writeMode } = this.state;

    return (
      <React.Fragment>
        <ConfirmDialog
          confirmButtonText="Delete"
          fullScreen={fullScreenDialogs}
          handleConfirm={this.handleConfirmDelete}
          handleCancel={this.handleConfirmClose}
          message={
            'Are you sure you want to delete "' + this.state.title + '" ?'
          }
          open={confirmOpen}
        />
        <Card className={classes.postcard} key={uid}>
          <Collapse
            in={!expanded}
            collapsedSize="0px"
            onClick={() => expand(uid)}
          >
            <Fade in={!expanded}>
              <CardHeader
                title={
                  <Grid
                    container
                    justifyContent="space-between"
                    direction="row"
                  >
                    <Grid item xs={8}>
                      {title}
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                      {date.toLocaleDateString(undefined, dateOptions)}
                    </Grid>
                  </Grid>
                }
                subheader={pin ? "Pinned" : ""}
              />
            </Fade>
          </Collapse>
          <Collapse in={expanded} collapsedSize="0px">
            <Fade in={expanded}>
              <React.Fragment>
                {!dispOnly && (
                  <React.Fragment>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item>
                        <Button
                          color="secondary"
                          onClick={() => this.setWriteMode(true)}
                        >
                          Write
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => this.setWriteMode(false)}
                        >
                          Preview
                        </Button>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={pin}
                              onClick={() => this.handleChange("pin", !pin)}
                            />
                          }
                          label="Pin"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                    <hr />
                  </React.Fragment>
                )}
                {writeMode && (
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={7} sm={9} md={10}>
                      <TextField
                        autoFocus
                        fullWidth
                        id="title"
                        label="title"
                        multiline
                        onChange={(event) =>
                          this.handleChange("title", event.target.value)
                        }
                        type="text"
                        value={title}
                      />
                    </Grid>
                    <Grid item xs={5} sm={3} md={2}>
                      <DatePicker
                        autoOk={true}
                        label="date"
                        value={date}
                        onChange={(changedDate) => {
                          this.handleDateChange(changedDate);
                        }}
                        animateYearScrolling
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        color="secondary"
                        fullWidth
                        id="content"
                        label="content"
                        multiline
                        onChange={(event) =>
                          this.handleChange("content", event.target.value)
                        }
                        value={content}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                )}
                {!writeMode && (
                  <React.Fragment>
                    <CardHeader
                      title={
                        <Grid
                          container
                          justifyContent="space-between"
                          direction="row"
                        >
                          <Grid item xs={8}>
                            {title}
                          </Grid>
                          <Grid item xs={4} sm={3} md={2} lg={1}>
                            {date.toLocaleDateString(undefined, dateOptions)}
                          </Grid>
                        </Grid>
                      }
                    />
                    <CardContent>
                      <ReactMarkdown escapeHtml={false} source={content} />
                    </CardContent>
                  </React.Fragment>
                )}
                {!dispOnly && (
                  <React.Fragment>
                    <Grid
                      container
                      justifyContent="space-between"
                      className={classes.cardActions}
                    >
                      <Grid item>
                        <Button
                          color="secondary"
                          onClick={() => this.handleCancel()}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => this.handleDelete()}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => this.handleSave()}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Fade>
          </Collapse>
        </Card>
      </React.Fragment>
    );
  }
}

Postcard.propTypes = {
  content: PropTypes.string,
  date: PropTypes.object.isRequired,
  dateOptions: PropTypes.object,
  expanded: PropTypes.bool,
  fullScreenDialogs: PropTypes.bool,
  pin: PropTypes.bool,
  title: PropTypes.string,
  dispOnly: PropTypes.bool,
  uid: PropTypes.string.isRequired,
  dirtyContent: PropTypes.string,
  dirtyDate: PropTypes.object,
  dirtyPin: PropTypes.bool,
  dirtyTitle: PropTypes.string,

  cancel: PropTypes.func,
  delete: PropTypes.func,
  expand: PropTypes.func,
  save: PropTypes.func,
  setDirty: PropTypes.func,
};

Postcard.defaultProps = {
  content: "Default Content",
  date: new Date(),
  dateOptions: { year: "numeric", month: "long", day: "numeric" },
  expanded: false,
  fullScreenDialogs: false,
  pin: false,
  title: "Default Title",
  dispOnly: false,
};

export default withStyles(styles)(Postcard);
