/**
 * @fileoverview JSS styles for SignUp.js
 */

const styles = (theme) => ({
  errorMessage: {
    padding: "1em 0 0 0",
  },
});

export default styles;
