/**
 * @fileoverview React Component for header navigation bar that will display on all pages of agwsu.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import styles from "./Navigation.jss.js";

import * as routes from "../../store/constants/routes";

import {
  AppBar,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  SwipeableDrawer,
  useScrollTrigger,
  withStyles,
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

/**
 * Creates a component wrapper that hides the child object when the window is scrolled down
 * @param {*} props
 */
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

/**
 * A header navigation bar with Site Title and Login/Logout Button
 * @implements {Component}
 */
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginDialogOpen: false,
      drawerOpen: false,
      userMenuOpen: false,
    };
  }

  /**
   * Called when props for component update
   * @param {*} prevProps previous props for class
   */
  componentDidUpdate(prevProps) {
    //on props.loggedIn change from falsey to truthy, close login dialog if open
    if (prevProps.loggedIn === false && this.props.loggedIn === true) {
      this.setState({ loginDialogOpen: false });
    }
  }

  /**
   * Handles the login action. Sets local state.
   */
  handleLogin = (email, password) => {
    this.props.login(email, password);
  };

  handleOpenDrawer = () => {
    this.setState({ drawerOpen: true });
  };

  handleCloseDrawer = () => {
    this.setState({ drawerOpen: false });
  };

  handleUserMenuToggle = () => {
    this.setState({ userMenuOpen: !this.state.userMenuOpen });
  };

  render() {
    const { admin, approved, classes, loggedIn, logout, openLogin } =
      this.props;
    const { drawerOpen, userMenuOpen } = this.state;

    return (
      <header>
        {loggedIn && (
          <SwipeableDrawer
            anchor="left"
            open={drawerOpen}
            onClose={this.handleCloseDrawer}
            onOpen={this.handleOpenDrawer}
          >
            <List>
              <Link to={routes.LANDING}>
                <ListItem>
                  <ListItemText>AGWSU</ListItemText>
                </ListItem>
              </Link>
              <Divider />
              {admin && approved && (
                <React.Fragment>
                  <Link to={routes.ANNOUNCEMENTS_DASHBOARD}>
                    <ListItem>
                      <ListItemIcon>
                        <AnnouncementIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>Announcements</ListItemText>
                    </ListItem>
                  </Link>
                  <ListItem button onClick={this.handleUserMenuToggle}>
                    <ListItemIcon>
                      <PeopleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                    {userMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse in={userMenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Link to={routes.USER_REQUESTS_DASHBOARD}>
                        <ListItem button className={classes.nested}>
                          <ListItemIcon>
                            <PersonAddIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Requests" />
                        </ListItem>
                      </Link>
                    </List>
                  </Collapse>
                  <Divider />
                </React.Fragment>
              )}
              <Link to={routes.ACCOUNT}>
                <ListItem>
                  <ListItemIcon>
                    <AccountCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Account</ListItemText>
                </ListItem>
              </Link>
              <Divider />
              <ListItem>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => logout()}
                >
                  LOGOUT
                </Button>
              </ListItem>
            </List>
          </SwipeableDrawer>
        )}
        <HideOnScroll>
          <AppBar color="default">
            <Toolbar className={classes.toolbar}>
              {loggedIn && (
                <Button onClick={this.handleOpenDrawer}>
                  <MenuIcon color="primary" />
                </Button>
              )}
              <Link to={routes.LANDING}>
                <Typography variant="h5" className={classes.letterSpacing3}>
                  AGWSU
                </Typography>
              </Link>
              {!loggedIn && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openLogin()}
                >
                  LOGIN
                </Button>
              )}
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </header>
    );
  }
}

Navigation.propTypes = {
  admin: PropTypes.bool.isRequired,
  approved: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool,
  userMessages: PropTypes.array,
  logout: PropTypes.func.isRequired,
  openLogin: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  userMessages: [],
  loggedIn: false,
  admin: false,
  approved: false,
};

export default withStyles(styles)(Navigation);
