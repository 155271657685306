/**
 * @fileoverview Reducer for any util actions
 */
import * as actions from "../constants/actionTypes";

const postSnackbarMessage = (state, action) => ({
  ...state,
  messages: [...state.messages, action.payload],
});

let handlers = {};
handlers[actions.POST_SNACKBAR_MESSAGE] = postSnackbarMessage;

const utilReducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export default utilReducer;
