/**
 * @fileoverview React Component for SignUp Dialog
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  withStyles,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import styles from "./SignUp.jss";

const byPropKey = (propertyName, value) => {
  return { [propertyName]: value };
};

/**
 * A Dialog with an Email & Password SignUp Form
 * @implements {Component}
 */
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      email: "",
      password: "",
      showPassword: false,
    };
  }

  /**
   * Prevents the MouseDownEvent from firing the default action
   * @param {*} event MouseDownEvent
   */
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Toggles the show password feature
   */
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { displayName, email, password } = this.state;

    this.props.handleSignUp(displayName, email, password);
  };

  render() {
    const { fullScreen, handleClose, open } = this.props;
    const { displayName, email, password } = this.state;
    const signUpDisclaimer =
      "This website is restricted to students associated with Wright State University Adventurers' Guild. To assist in this, please use your student email when registering.\n\nAfter registering, please verify your email address.\n\nAn Administrator will approve your registration after your email is validated.\n\nYou will receive a welcome email with group rules after you are approved.";

    return (
      <Dialog
        open={open}
        onClose={() => handleClose()}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={this.onSubmit}>
          <DialogTitle id="form-dialog-title">REGISTRATION</DialogTitle>
          <DialogContent>
            <ReactMarkdown escapeHtml={false} source={signUpDisclaimer} />
            <InputLabel htmlFor="displayName">Display Name</InputLabel>
            <Input
              autoFocus
              margin="dense"
              id="displayName"
              label="Display Name"
              required
              type="text"
              value={displayName}
              onChange={(event) =>
                this.setState(byPropKey("displayName", event.target.value))
              }
              fullWidth
            />
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              required
              type="email"
              value={email}
              onChange={(event) =>
                this.setState(byPropKey("email", event.target.value))
              }
              fullWidth
            />
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              required
              type={this.state.showPassword ? "text" : "password"}
              value={password}
              onChange={(event) =>
                this.setState(byPropKey("password", event.target.value))
              }
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose()}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button variant="contained" color="secondary" type="submit">
              Sign Up
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

SignUp.propTypes = {
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSignUp: PropTypes.func.isRequired,
};

SignUp.defaultProps = {
  open: false,
  fullScreen: false,
};

export default withStyles(styles)(SignUp);
