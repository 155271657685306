/**
 * @fileoverview Reducer for any users actions
 */
import * as actions from "../constants/actionTypes";

const usersChanged = (state, action) => ({
  ...state,
  users: action.payload,
});

let handlers = {};
handlers[actions.USERS_CHANGED] = usersChanged;

const usersReducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export default usersReducer;
