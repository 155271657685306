/**
 * @fileoverview React Component for Login Dialog
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  withStyles,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import styles from "./Login.jss";

const byPropKey = (propertyName, value) => {
  return { [propertyName]: value };
};

/**
 * A Dialog with an Email & Password Login Form
 * @implements {Component}
 */
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
    };
  }

  /**
   * Prevents the MouseDownEvent from firing the default action
   * @param {*} event MouseDownEvent
   */
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Toggles the show password feature
   */
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    this.props.handleLogin(email, password);
  };

  render() {
    const { failedLogin, fullScreen, handleClose, open, forgotPassword } =
      this.props;
    const { email, password } = this.state;
    let forgotEnabled = email !== "";
    let loginEnabled = email !== "" && password !== "";

    return (
      <Dialog
        open={open}
        onClose={() => handleClose()}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={this.onSubmit}>
          <DialogTitle id="form-dialog-title">LOGIN</DialogTitle>
          <DialogContent>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              value={email}
              onChange={(event) =>
                this.setState(byPropKey("email", event.target.value))
              }
              fullWidth
            />
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              margin="dense"
              id="password"
              label="Password"
              type={this.state.showPassword ? "text" : "password"}
              value={password}
              onChange={(event) =>
                this.setState(byPropKey("password", event.target.value))
              }
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose()}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            {failedLogin && (
              <Button
                variant="outlined"
                onClick={() => forgotPassword(email)}
                disabled={!forgotEnabled}
              >
                Forgot Password
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!loginEnabled}
            >
              Login
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

Login.propTypes = {
  open: PropTypes.bool,
  errorMessage: PropTypes.string,
  failedLogin: PropTypes.bool,
  fullScreen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
};

Login.defaultProps = {
  open: false,
  errorMessage: "",
  failedLogin: false,
  fullScreen: false,
};

export default withStyles(styles)(Login);
