/**
 * @fileoverview JSS styles for Landing.js
 */

import { siteTheme } from "../../themes";

import contact_bg from "../../images/contact_bg.jpg";
import header_bg_sm from "../../images/header_bg_sm.webp";
import header_bg_md from "../../images/header_bg_md.webp";
import header_bg_lg from "../../images/header_bg_lg.webp";
import header_bg_xl from "../../images/header_bg_xl.webp";
import header_bg_4k from "../../images/header_bg_4k.webp";
import infoBg from "../../images/stormy_sky.jpg";
import infoSideBg from "../../images/info_side.webp";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
  callToAction: {
    margin: "1em",

    /* Resize the call out button based on screen size */
    [siteTheme.breakpoints.between("xs", "md")]: {
      fontSize: "1em",
    },
    [siteTheme.breakpoints.up("md")]: {
      fontSize: "2em",
      padding: "0.2em 1em",
    },
  },
  infoList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  infoSide: {
    backgroundImage: `url(${infoSideBg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: "20em",
  },
  parallax: {
    /* Resize the landing background based on screen size */
    [siteTheme.breakpoints.between("xs", "sm")]: {
      backgroundImage: `url(${header_bg_sm})`,
      height: "250px",
    },
    [siteTheme.breakpoints.between("sm", "md")]: {
      backgroundImage: `url(${header_bg_md})`,
      height: "375px",
    },
    [siteTheme.breakpoints.between("md", "lg")]: {
      backgroundImage: `url(${header_bg_lg})`,
      height: "475px",
    },
    [siteTheme.breakpoints.between("lg", 1921)]: {
      backgroundImage: `url(${header_bg_xl})`,
      height: "675px",
    },
    [siteTheme.breakpoints.up(1921)]: {
      backgroundImage: `url(${header_bg_4k})`,
      height: "1349px",
    },
    width: "100%",

    /* Create the parallax scrolling effect */
    backgroundAttachment: "fixed",
    backgroundPosition: "0 0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    padding: "5em 0em 0em 0em",
  },
  sectionContact: {
    backgroundImage: `url(${contact_bg})`,
  },
  sectionInfo: {
    backgroundImage: `url(${infoBg})`,
  },
  title: {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: "rgba(0,0,0,0.6)",
    width: "100%",
    padding: "0 0 0 1em",
    [siteTheme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    [siteTheme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
});

export default styles;
