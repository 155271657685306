/**
 * @fileoverview React Component for footer bar that will display on all pages of agwsu.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";

import styles from "./Footer.jss.js";

import {
  Avatar,
  Badge,
  Grid,
  SvgIcon,
  Box,
  Divider,
  withStyles,
} from "@material-ui/core";

import * as routes from "../../store/constants/routes";

import FacebookIcon from "@material-ui/icons/Facebook";

import avatarMax from "../../images/max.webp";
import avatarNeal from "../../images/neal.webp";
import linkedin from "../../images/linkedin.webp";
import { ReactComponent as SteamIcon } from "../../images/icons/steam_icon.svg";
import { ReactComponent as UnsplashIcon } from "../../images/icons/unsplash_icon.svg";

/**
 * A footer bar with social media links & developer attribution
 * @implements { Component }
 */
class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.footer}>
        <div className={classes.xsStack}>
          <a
            href="https://www.facebook.com/groups/117011725113705/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon fontSize="large" />
          </a>
          <a
            href="https://steamcommunity.com/groups/AG-WSU"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SvgIcon
              viewBox="-3 -3 30 30"
              component={SteamIcon}
              fontSize="large"
            />
          </a>
        </div>
        <div className={classes.xsStack}>
          <Link to={routes.PRIVACY_POLICY}>Privacy Policy</Link>
          <Box px={1} my={0.4}></Box>
          <Divider orientation="vertical" flexItem />
          <Box px={1} my={0.4}></Box>
          <Link to={routes.TERMS_OF_SERVICE}>Terms of Service</Link>
        </div>
        <div className={classes.footerRight}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={6}>
              <a
                href="https://unsplash.com/photos/omL9Q8B2YCI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIcon
                  viewBox="-3 -3 30 30"
                  component={UnsplashIcon}
                  fontSize="large"
                />
              </a>
            </Grid>
            <Grid item xs={6} container direction="row">
              <Grid item container justifyContent="center" xs={4}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <a
                      href="https://www.linkedin.com/in/maxlepper/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar
                        alt="linkedIn"
                        src={linkedin}
                        id="globalSmallAvatar"
                      />
                    </a>
                  }
                >
                  <Avatar alt="Max Lepper" src={avatarMax} />
                </Badge>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item container justifyContent="center" xs={4}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <a
                      href="https://www.linkedin.com/in/nealstrobl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar
                        alt="linkedIn"
                        src={linkedin}
                        id="globalSmallAvatar"
                      />
                    </a>
                  }
                >
                  <Avatar alt="Neal Strobl" src={avatarNeal} />
                </Badge>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
