/**
 * @fileoverview React Component that displays user requests dashboard
 */

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Grid, Typography, withStyles } from "@material-ui/core";

import {
  approveUser,
  rejectUser,
  postSnackbarMessage,
} from "../../store/actions";
import * as selectors from "../../store/selectors";
import * as firebaseUsers from "../../firebase/firebaseUsers";

import UserRequestsCard from "../UserRequestsCard/UserRequestsCard";

import styles from "./UserRequestsDashboard.jss";

/**
 * React Component that displays user requests dashboard
 * @implements { Component }
 */
class UserRequestsDashboard extends Component {
  componentDidMount() {
    firebaseUsers.attachUsersListener();
  }

  componentWillUnmount() {
    firebaseUsers.detachUsersListener();
  }

  handleApproveUser = (uid) => {
    let user = this.props.unapprovedUsers.find((user) => user.uid === uid);
    this.props.approveUser(
      uid,
      user.emails[user.emails.length - 1],
      user.displayNames[user.displayNames.length - 1]
    );
    this.props.postSnackbarMessage("User Approved");
  };

  handleRejectUser = (uid, response) => {
    let user = this.props.unapprovedUsers.find((user) => user.uid === uid);
    this.props.rejectUser(
      uid,
      user.emails[user.emails.length - 1],
      user.displayNames[user.displayNames.length - 1],
      response
    );
    this.props.postSnackbarMessage("User Rejected");
  };

  render() {
    const { unapprovedUsers, classes, fullScreenDialogs } = this.props;
    return (
      <section>
        <Grid container spacing={2} className={classes.dashboard}>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
            xs={12}
          >
            <Typography variant="h5">User Requests</Typography>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid container item alignItems="center" xs={12} spacing={2}>
            {unapprovedUsers.map((unapprovedUser, index) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <UserRequestsCard
                    fullScreenDialogs={fullScreenDialogs}
                    uid={unapprovedUser.uid}
                    displayName={
                      unapprovedUser.displayNames[
                        unapprovedUser.displayNames.length - 1
                      ]
                    }
                    email={
                      unapprovedUser.emails[unapprovedUser.emails.length - 1]
                    }
                    accept={this.handleApproveUser}
                    reject={this.handleRejectUser}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </section>
    );
  }
}

UserRequestsDashboard.propTypes = {
  fullScreenDialogs: PropTypes.bool,
};

UserRequestsDashboard.defaultProps = {
  fullScreenDialogs: false,
};

const mapStateToProps = (state) => ({
  unapprovedUsers: selectors.getUnapprovedUsers(state),
  pendingAction: selectors.isPendingAnnouncementsAction(state),
});

const mapDispatchToProps = (dispatch) => ({
  approveUser: bindActionCreators(approveUser, dispatch),
  rejectUser: bindActionCreators(rejectUser, dispatch),
  postSnackbarMessage: bindActionCreators(postSnackbarMessage, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserRequestsDashboard));
