import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { Link } from "react-router-dom";

import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Grid,
  withStyles,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import styles from "./Verification.jss";
import {
  getEmailFromActionCode,
  resetPassword,
  verifyEmail,
} from "../../store/actions";
import * as routes from "../../store/constants/routes";
import * as selectors from "../../store/selectors";

class Verification extends Component {
  constructor(props) {
    super(props);
    let query = this.useQuery(props.location);
    props.getEmailFromActionCode(query.get("oobCode"));
    this.state = {
      newPassword: "",
      showNewPassword: false,
    };
  }

  useQuery = (location) => {
    return new URLSearchParams(location.search);
  };

  /**
   * Prevents the MouseDownEvent from firing the default action
   * @param {*} event MouseDownEvent
   */
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Toggles the show password feature
   */
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  /**
   * Prevents the MouseDownEvent from firing the default action
   * @param {*} event MouseDownEvent
   */
  handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Toggles the show new password feature
   */
  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  render() {
    const {
      classes,
      email,
      location,
      loggedIn,
      openLogin,
      resetPassword,
      verifyEmail,
    } = this.props;
    const { newPassword, showNewPassword } = this.state;
    let query = this.useQuery(location);
    let mode = query.get("mode");
    let oobCode = query.get("oobCode");

    return (
      <section>
        {mode === "resetPassword" && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                autofocus
                id="email"
                label="Email"
                type="text"
                value={email}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="newPassword">New Password</InputLabel>
              <Input
                autoFocus
                id="newPassword"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(event) =>
                  this.setState({ newPassword: event.target.value })
                }
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowNewPassword}
                      onMouseDown={this.handleMouseDownNewPassword}
                    >
                      {this.state.showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item>
              <Link to={routes.LANDING}>
                <Button variant="outlined" color="secondary">
                  Cancel
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => resetPassword(oobCode, newPassword)}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        )}
        {mode === "verifyAndChangeEmail" && !loggedIn && (
          <center>
            <Button
              className={classes.callToAction}
              variant="contained"
              color="primary"
              onClick={() => openLogin()}
            >
              Login to Verify New Email
            </Button>
          </center>
        )}
        {mode === "verifyAndChangeEmail" && loggedIn && (
          <center>
            <Button
              className={classes.callToAction}
              variant="contained"
              color="primary"
              onClick={() => verifyEmail(oobCode)}
            >
              Verify New Email
            </Button>
          </center>
        )}
        {mode === "verifyEmail" && !loggedIn && (
          <center>
            <Button
              className={classes.callToAction}
              variant="contained"
              color="primary"
              onClick={() => openLogin()}
            >
              Login to Verify Email
            </Button>
          </center>
        )}
        {mode === "verifyEmail" && loggedIn && (
          <center>
            <Button
              className={classes.callToAction}
              variant="contained"
              color="primary"
              onClick={() => verifyEmail(oobCode)}
            >
              Verify Email
            </Button>
          </center>
        )}
      </section>
    );
  }
}

Verification.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  openLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedIn: selectors.isLoggedIn(state),
  email: selectors.getEmail(state),
  emailVerified: selectors.isEmailVerified(state),
});

const mapDispatchToProps = (dispatch) => ({
  verifyEmail: bindActionCreators(verifyEmail, dispatch),
  resetPassword: bindActionCreators(resetPassword, dispatch),
  getEmailFromActionCode: bindActionCreators(getEmailFromActionCode, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Verification)));
