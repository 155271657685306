/**
 * @fileoverview Main reducer for project
 */

import userReducer from "./userReducer";
import usersReducer from "./usersReducer";
import announcementsReducer from "./announcementsReducer";
import utilReducer from "./utilReducer";

export const initialState = {
  user: {
    admin: false,
    approved: true,
    displayNames: [""],
    emails: [""],
    emailVerified: false,
    messages: [""],
    failedLogin: false,
    loggedIn: true,
    pendingActions: 0,
    uid: null,
  },
  announcements: {
    announcements: [],
    announcementsConfig: {},
    messages: [],
    pendingAction: false,
  },
  users: {
    users: [],
    messages: [],
    pendingAction: false,
  },
  util: {
    messages: [],
  },
};

/**
 * Main Reducer for website
 * @param {*} state Immutable site-wide state
 * @param {*} action Incoming action
 */
const reducer = (state = initialState, action) => {
  //if no state currently loaded, try to load from localStorage
  if (state.user === undefined) {
    let cachedState = localStorage.getItem("state");
    if (cachedState) {
      return JSON.parse(cachedState);
    }
  }

  //apply actions to state
  let newState = {
    user: userReducer(state.user, action),
    users: usersReducer(state.users, action),
    announcements: announcementsReducer(state.announcements, action),
    util: utilReducer(state.util, action),
  };

  //save state to local storage
  localStorage.setItem("state", JSON.stringify(newState));
  return newState;
};

export default reducer;
