/**
 * @fileoverview JSS styles for UserRequestsDashboard.js
 */

const styles = (theme) => ({
  dashboard: {
    marginTop: "1em",
  },
});

export default styles;
