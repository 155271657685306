/**
 * @fileoverview React Component for account management
 */

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Filter from "bad-words";

import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Input,
  InputAdornment,
  InputLabel,
  withStyles,
} from "@material-ui/core";

import { Done, Info, Visibility, VisibilityOff } from "@material-ui/icons";

import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

import {
  deleteCurrentUser,
  postSnackbarMessage,
  sendVerificationEmail,
  resubmitApprovalRequest,
  updateDisplayName,
  updateEmail,
  updatePassword,
} from "../../store/actions";
import * as selectors from "../../store/selectors";
import styles from "./Account.jss";
/**
 * React Component that displays announcements dashboard
 * @implements { Component }
 */
class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
      displayName: props.displayName,
      email: props.email,
      password: "",
      newPassword: "",
      showPassword: false,
      showNewPassword: false,
      deleteConfirmMessage: "This action cannot be undone.",
    };
  }

  filter = new Filter();

  /**
   * Called when props for component update
   * @param {*} prevProps previous props for class
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.email !== this.props.email ||
      prevProps.displayName !== this.props.displayName
    ) {
      this.resetPage();
    }
  }

  resetPage = () => {
    this.setState({
      displayName: this.props.displayName,
      email: this.props.email,
      password: "",
      newPassword: "",
      showPassword: false,
      showNewPassword: false,
      deleteConfirmMessage: "This action cannot be undone.",
    });
  };

  /**
   * Prevents the MouseDownEvent from firing the default action
   * @param {*} event MouseDownEvent
   */
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Toggles the show password feature
   */
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  /**
   * Prevents the MouseDownEvent from firing the default action
   * @param {*} event MouseDownEvent
   */
  handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Toggles the show new password feature
   */
  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  handleCancel = () => {
    this.resetPage();
  };

  handleDelete = () => {
    this.setState({ confirmOpen: true });
  };

  handleDeleteConfirm = (response) => {
    this.setState({ confirmOpen: false });
    this.props.deleteCurrentUser(response);
  };

  handleDeleteCancel = () => {
    this.setState({
      confirmOpen: false,
      deleteConfirmMessage: "This action cannot be undone.",
    });
  };

  /**
   * Handle user clicking Save Button
   */
  handleSave = () => {
    if (this.state.email !== this.props.email && this.state.password) {
      this.props.updateEmail(this.state.email, this.state.password);
    }
    if (
      this.state.displayName !== this.props.displayName &&
      this.state.password
    ) {
      if (this.filter.isProfane(this.state.displayName)) {
        this.props.postSnackbarMessage(
          "Prohibited word used: display name not updated."
        );
      } else {
        this.props.updateDisplayName(this.state.displayName);
      }
    }
    if (this.state.newPassword !== "" && this.state.password) {
      this.props.updatePassword(this.state.password, this.state.newPassword);
    }
  };

  render() {
    const { classes, emailVerified, fullScreenDialogs, rejected } = this.props;
    const {
      confirmOpen,
      deleteConfirmMessage,
      displayName,
      email,
      newPassword,
      password,
      showPassword,
      showNewPassword,
    } = this.state;
    let saveEnabled =
      (this.state.email !== this.props.email ||
        this.state.displayName !== this.props.displayName ||
        this.state.newPassword !== "") &&
      this.state.password;

    return (
      <section>
        <ConfirmDialog
          cancelButtonText="Nevermind"
          confirmButtonText="Delete Account"
          fullScreen={fullScreenDialogs}
          handleConfirm={this.handleDeleteConfirm}
          handleCancel={this.handleDeleteCancel}
          message={deleteConfirmMessage}
          open={confirmOpen}
          inputField={true}
          password={true}
          textAreaLabel="Enter your password below to delete your account"
          title="Really Delete Your Account?"
        />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h5">Account Details</Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="displayName">Display Name</InputLabel>
            <Input
              id="displayName"
              label="Display Name"
              required
              type="text"
              value={displayName}
              onChange={(event) =>
                this.setState({ displayName: event.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              id="email"
              label="Email Address"
              required
              type="email"
              value={email}
              onChange={(event) => this.setState({ email: event.target.value })}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  {emailVerified ? (
                    <Tooltip
                      title="Email Is Verified"
                      aria-label="Email Verified"
                    >
                      <Done />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Email Not Verified"
                      aria-label="Email Not Verified"
                    >
                      <Info />
                    </Tooltip>
                  )}
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="password">Current Password*</InputLabel>
            <Input
              id="password"
              label="Current Password"
              required
              type={this.state.showPassword ? "text" : "password"}
              value={password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Tooltip title="Hide Password" aria-label="Hide Password">
                        <VisibilityOff />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show Password" aria-label="Show Password">
                        <Visibility />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="newPassword">New Password</InputLabel>
            <Input
              id="newPassword"
              label="New Password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(event) =>
                this.setState({ newPassword: event.target.value })
              }
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowNewPassword}
                    onMouseDown={this.handleMouseDownNewPassword}
                  >
                    {this.state.showNewPassword ? (
                      <Tooltip title="Hide Password" aria-label="Hide Password">
                        <VisibilityOff />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Show Password" aria-label="Show Password">
                        <Visibility />
                      </Tooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => this.handleCancel()}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={!saveEnabled}
              onClick={() => this.handleSave()}
            >
              Save Changes
            </Button>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {rejected && (
            <Grid item xs={12}>
              <Button
                onClick={() => this.props.resubmitApprovalRequest()}
                variant="contained"
                color="secondary"
              >
                Resubmit Approval Request
              </Button>
            </Grid>
          )}
          {!emailVerified && (
            <Grid item xs={12}>
              <Button
                onClick={() => this.props.sendVerificationEmail()}
                variant="contained"
                color="secondary"
              >
                Resend Verification Email
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              onClick={() => this.handleDelete()}
              variant="outlined"
              className={classes.deleteAccountButton}
            >
              Delete Account
            </Button>
          </Grid>
        </Grid>
      </section>
    );
  }
}

Account.propTypes = {
  fullScreenDialogs: PropTypes.bool,
};

Account.defaultProps = {
  fullScreenDialogs: false,
};

const mapStateToProps = (state) => ({
  email: selectors.getEmail(state),
  emailVerified: selectors.isEmailVerified(state),
  displayName: selectors.getDisplayName(state),
  approved: selectors.isApproved(state),
  rejected: selectors.isRejected(state),
});

const mapDispatchToProps = (dispatch) => ({
  deleteCurrentUser: bindActionCreators(deleteCurrentUser, dispatch),
  postSnackbarMessage: bindActionCreators(postSnackbarMessage, dispatch),
  resubmitApprovalRequest: bindActionCreators(
    resubmitApprovalRequest,
    dispatch
  ),
  sendVerificationEmail: bindActionCreators(sendVerificationEmail, dispatch),
  updateDisplayName: bindActionCreators(updateDisplayName, dispatch),
  updateEmail: bindActionCreators(updateEmail, dispatch),
  updatePassword: bindActionCreators(updatePassword, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Account));
