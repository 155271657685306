/**
 * @fileoverview Initializes and configures agwsu project firebase instance connection
 * @exports { auth, db }
 */

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

//firebase config object from firebase console
const firebaseConfig = {
  apiKey: "AIzaSyDcMsYrs2r93k2LDZyPw8lELmN-I9ECw-Q",
  authDomain: "agwsu-f07fc.firebaseapp.com",
  databaseURL: "https://agwsu-f07fc.firebaseio.com",
  projectId: "agwsu-f07fc",
  storageBucket: "agwsu-f07fc.appspot.com",
  messagingSenderId: "865626143568",
  appId: "1:865626143568:web:092cf72b6f5d5009ba4a55",
  measurementId: "G-FLS2XX2LTD",
};

//if firebase is not initialized, initialize it
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

//pull out firebase auth(), firebase() to object
const auth = firebase.auth();
const db = firebase.firestore();

//export statement
export { auth, db };
