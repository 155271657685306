/**
 * @fileoverview JSS styles for Navigation.js
 */

const styles = (theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

export default styles;
