/**
 * @fileoverview Material-UI theme for project
 */

import { responsiveFontSizes } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

export const colors = {
  PRIMARY: "#E4DEC7", //faded gold from wsu branding
  PRIMARY_CONTRAST: "#2F2D2E", //soft black
  SECONDARY: "#CBA052", //primary gold from wsu branding
  SECONDARY_CONTRAST: "#2F2D2E", //soft black
  ERROR: "#FF9800", //Default Material-UI warning color
  ERROR_CONTRAST: "#E4DEC7", //faded gold from wsu branding
  BACKGROUND: "#2F2D2E", //soft black
};

export const siteTheme = responsiveFontSizes(
  createTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          section: {
            padding: "5em 1em 5em 1em",
          },
          "#globalSmallAvatar": {
            width: "1em",
            height: "1em",
            color: colors.SECONDARY,
          },
        },
      },
      MuiDialogContent: {
        root: {
          padding: "0em 2em",
          overflowY: "hidden",
        },
      },
      MuiDialogActions: {
        root: {
          padding: "2em",
          justifyContent: "space-between",
        },
      },
      MuiDialogTitle: {
        root: {
          padding: "1em 2em",
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: colors.SECONDARY,
        },
      },
    },
    palette: {
      type: "dark",
      primary: {
        main: colors.PRIMARY,
        contrastText: colors.PRIMARY_CONTRAST,
      },
      secondary: {
        main: colors.SECONDARY,
        contrastText: colors.SECONDARY_CONTRAST,
      },
      error: {
        main: colors.ERROR,
        contrastText: colors.ERROR_CONTRAST,
      },
      text: {
        primary: colors.PRIMARY,
        secondary: colors.SECONDARY,
      },
      background: {
        default: colors.BACKGROUND,
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      h1: {
        fontFamily: [
          "Beholder",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h2: {
        fontFamily: [
          "Beholder",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h3: {
        fontFamily: [
          "Beholder",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h4: {
        fontFamily: [
          "Beholder",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h5: {
        fontFamily: [
          "Beholder",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h6: {
        fontFamily: [
          "Beholder",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      subtitle1: {
        fontFamily: [
          "Duralith",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      subtitle2: {
        fontFamily: [
          "Duralith",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      body1: {
        fontFamily: [
          "Duralith",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      body2: {
        fontFamily: [
          "Duralith",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      button: {
        fontFamily: [
          "Duralith",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      caption: {
        fontFamily: [
          "Duralith",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      overline: {
        fontFamily: [
          "Duralith",
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    },
  })
);
