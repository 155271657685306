/**
 * @fileoverview Main compnent for AGWSU application.  Contains all components for AGWSU.
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Router, Redirect, Route, Switch } from "react-router-dom";

import history from "../../history";

import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  IconButton,
  MuiThemeProvider,
  Snackbar,
  withStyles,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import { authObserver } from "../../firebase/firebaseAuth";
import {
  announcementsObserver,
  announcementsConfigObserver,
} from "../../firebase/firebaseAnnouncements";

import Account from "../Account/Account";
import AnnouncementsDashboard from "../AnnoucementsDashboard/AnnouncementsDashboard";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Login from "../Login/Login";
import Navigation from "../Navigation/Navigation";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../TermsOfService/TermsOfService";
import UserRequestsDashboard from "../UserRequestsDashboard/UserRequestsDashboard";
import Verification from "../Verification/Verification";
import withMediaQuery from "../withMediaQuery/withMediaQuery";

import { login, logout, sendPasswordResetEmail } from "../../store/actions";
import * as routes from "../../store/constants/routes";
import * as selectors from "../../store/selectors";

import "./App.css";
import { siteTheme } from "../../themes";
import styles from "./App.jss";

/**
 * Main component for application
 * @implements { Component }
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginDialogOpen: false,
      snackbarDuration: 6000,
      snackbarMessage: "",
      snackbarOpen: false,
    };
  }

  componentWillUnmount() {
    authObserver.unsubscribe();
    announcementsObserver.unsubscribe();
    announcementsConfigObserver.unsubscribe();
  }

  /**
   * Called when props for component update
   * @param {*} prevProps previous props for class
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.setState({ loginDialogOpen: false });
    }

    //announcements message to display
    if (
      prevProps.announcementsMessages.length <
      this.props.announcementsMessages.length
    ) {
      this.addMessageToSnackbar(
        this.props.announcementsMessages[
          this.props.announcementsMessages.length - 1
        ]
      );
    }
    //user message to display
    if (prevProps.userMessages.length < this.props.userMessages.length) {
      this.addMessageToSnackbar(
        this.props.userMessages[this.props.userMessages.length - 1]
      );
    }
    //util message to display
    if (prevProps.utilMessages.length < this.props.utilMessages.length) {
      this.addMessageToSnackbar(
        this.props.utilMessages[this.props.utilMessages.length - 1]
      );
    }
  }

  addMessageToSnackbar(message) {
    if (this.state.snackbarOpen) {
      this.setState({
        snackbarMessage: this.state.snackbarMessage + " | " + message,
        snackbarDuration: this.state.snackbarDuration + 6000,
      });
    } else {
      //if snackbar closed, just display new message
      this.setState({
        snackbarMessage: message,
        snackbarOpen: true,
        snackbarDuration: 6000,
      });
    }
  }

  /**
   * Handle the Login Dialog open action. Sets local state.
   */
  handleOpenLoginDialog = () => {
    this.setState({ loginDialogOpen: true });
  };

  /**
   * Handles the Login Dialog close action. Sets local state.
   */
  handleCloseLoginDialog = () => {
    this.setState({ loginDialogOpen: false });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const {
      classes,
      isAdmin,
      isApproved,
      isLoggedIn,
      isPendingActions,
      hasFailedLogin,
      login,
      logout,
      userMessages,
      sendPasswordResetEmail,
    } = this.props;
    const { loginDialogOpen, snackbarDuration, snackbarMessage, snackbarOpen } =
      this.state;
    const fullScreenDialogs = this.props.mediaQuery;

    return (
      <MuiThemeProvider theme={siteTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <Router history={history}>
            <div className={classes.app}>
              <Navigation
                admin={isAdmin}
                approved={isApproved}
                loggedIn={isLoggedIn}
                openLogin={this.handleOpenLoginDialog}
                logout={logout}
              />
              <Backdrop className={classes.backdrop} open={isPendingActions}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Login
                errorMessage={
                  userMessages ? userMessages[userMessages.length - 1] : ""
                }
                failedLogin={hasFailedLogin}
                fullScreen={fullScreenDialogs}
                handleClose={this.handleCloseLoginDialog}
                handleLogin={login}
                open={loginDialogOpen}
                forgotPassword={sendPasswordResetEmail}
              />
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={snackbarOpen}
                autoHideDuration={snackbarDuration}
                onClose={this.handleSnackbarClose}
                message={snackbarMessage}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleSnackbarClose}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
              <Switch>
                <Route exact path={routes.ACCOUNT}>
                  {isLoggedIn ? (
                    <Account fullScreenDialogs={fullScreenDialogs} />
                  ) : (
                    <Redirect to={routes.LANDING} />
                  )}
                </Route>
                <Route exact path={routes.ANNOUNCEMENTS_DASHBOARD}>
                  {isLoggedIn && isAdmin ? (
                    <AnnouncementsDashboard
                      fullScreenDialogs={fullScreenDialogs}
                    />
                  ) : (
                    <Redirect to={routes.LANDING} />
                  )}
                </Route>
                <Route exact path={routes.USER_REQUESTS_DASHBOARD}>
                  {isLoggedIn && isAdmin ? (
                    <UserRequestsDashboard
                      fullScreenDialogs={fullScreenDialogs}
                    />
                  ) : (
                    <Redirect to={routes.LANDING} />
                  )}
                </Route>
                <Route exact path={routes.LANDING}>
                  <Landing fullScreenDialogs={fullScreenDialogs} />
                </Route>
                <Route exact path={routes.TERMS_OF_SERVICE}>
                  <TermsOfService />
                </Route>
                <Route exact path={routes.PRIVACY_POLICY}>
                  <PrivacyPolicy />
                </Route>
                <Route exact path={routes.VERIFICATION}>
                  <Verification openLogin={this.handleOpenLoginDialog} />
                </Route>
              </Switch>
              <Footer />
            </div>
          </Router>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  utilMessages: selectors.getUtilMessages(state),
  userMessages: selectors.getUserMessages(state),
  announcementsMessages: selectors.getAnnouncementsMessages(state),
  isPendingActions: selectors.isPendingActions(state),
  hasFailedLogin: selectors.hasFailedLogin(state),
  isPendingUserActions: selectors.isPendingUserActions(state),
  isLoggedIn: selectors.isLoggedIn(state),
  isAdmin: selectors.isAdmin(state),
  isApproved: selectors.isApproved(state),
});

const mapDispatchToProps = (dispatch) => ({
  login: bindActionCreators(login, dispatch),
  logout: bindActionCreators(logout, dispatch),
  sendPasswordResetEmail: bindActionCreators(sendPasswordResetEmail, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMediaQuery(siteTheme.breakpoints.down("sm"))(App)));
