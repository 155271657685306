/**
 * @fileoverview JSS styles for Navigation.js
 */

const styles = (theme) => ({
  postcard: {
    padding: "1em",
  },
  cardActions: {
    paddingTop: "1em",
  },
});

export default styles;
