/**
 * @fileoverview JSS styles for App.js
 */

const styles = (theme) => ({
  app: {
    minWidth: "100%",
    minHeight: "100vh",
    overflowX: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
  smallAvatar: {
    width: "1em",
    height: "1em",
  },
});

export default styles;
