/**
 * @fileoverview Methods for interfacing with firebase announcements
 * @exports { doCreateAnnouncement, doDeleteAnnouncement, doEditAnnouncement announcementsObserver }
 */
import firebase from "firebase/app";

import { auth, db } from "./firebaseInit";
import store from "../store/store";

import {
  announcementsChanged,
  announcementsConfigChanged,
} from "../store/actions";

let announcements = db.collection("announcements");
let announcementsConfig = db.collection("config").doc("announcements");

/**
 * Listener for firebase announcements changes
 */
export const announcementsConfigObserver = announcementsConfig.onSnapshot(
  (docSnapshot) => {
    store.dispatch(announcementsConfigChanged(docSnapshot.data()));
  }
);

export const doSetMaxLandingWeeks = (weeks) => {
  return announcementsConfig.update({ maxLandingWeeks: weeks });
};

/**
 * Listener for firebase announcements changes
 */
export const announcementsObserver = announcements
  .where("deleted", "==", false)
  .onSnapshot((collectionSnapshot) => {
    let collection = [];
    collectionSnapshot.forEach((document) => {
      collection.push({ id: document.id, ...document.data() });
    });
    store.dispatch(announcementsChanged(collection));
  });

export const doCreateAnnouncement = (title, content, date) => {
  return announcements.add({
    content: content,
    created: firebase.firestore.Timestamp.now(),
    createdBy: auth.currentUser.uid,
    date: firebase.firestore.Timestamp.fromDate(date),
    deleted: false,
    lastEdited: firebase.firestore.Timestamp.now(),
    lastEditedBy: auth.currentUser.uid,
    title: title,
  });
};

export const doDeleteAnnouncement = (id) => {
  return announcements.doc(id).delete();
};

export const doEditAnnouncement = (id, title, content, date, pin) => {
  return announcements.doc(id).update({
    content: content,
    date: firebase.firestore.Timestamp.fromDate(date),
    lastEdited: firebase.firestore.Timestamp.now(),
    lastEditedBy: auth.currentUser.uid,
    pin: pin,
    title: title,
  });
};
