/**
 * @fileoverview Methods for interfacing with firebase mail
 * @exports { doInitUser }
 */

import { db } from "./firebaseInit";

let mail = db.collection("mail");

/**
 * Writes email to firestore mail collection for cloud email system to pick up.
 * @param {String} from Address of sender. Set null or empty string to use default (noreply).
 * @param {*} to Recipiant of Email. Single address or array of addresses.
 * @param {*} cc Carbon Copy. Single address or array of addresses. Set null or empty string if not using.
 * @param {*} bcc Blind Carbon Copy. Single address or array of addresses. Set null or empty string if not using.
 * @param {String} subject Subject of Email. Required.
 * @param {String} html Body of Email. Required.
 */
export const doSendEmail = (from, to, cc, bcc, subject, html) => {
  if (!to || !subject || !html) return;
  let email = {};
  from ? (email.from = "noreply@agwsu.org") : (email.from = from);
  if (cc) email.cc = cc;
  if (bcc) email.bcc = bcc;
  if (to) email.to = to;
  email.message = { subject: subject, html: html };
  return mail.add(email);
};

/**
 * Sends Approved Email for uid given
 * @param {String} email
 * @param {String} displayName
 */
export const doSendApprovalEmail = (email, displayName) => {
  return doSendEmail(
    null,
    email,
    null,
    null,
    "Welcome to AGWSU!",
    "Hello "
      .concat(displayName)
      .concat(
        ",<br><br>Your registration for AGWSU.org has been <b>approved</b>!<br><br>"
      )
      .concat(
        'Please visit <a href="https://agwsu.org/">AGWSU</a> and login to use all of our features!<br><br>'
      )
      .concat(
        "ATTENTION: AGWSU.org enforces the Wright State University Code of Student Conduct and will report violations to the University.<br>"
      )
      .concat(
        'The Wright State University Code of Student Conduct can be found <a href="https://policy.wright.edu/">here</a>.<br><br>'
      )
      .concat("Thank you for joining!")
  );
};

/**
 * Sends rejection email for a user request.
 * @param {String} email
 * @param {String} displayName
 * @param {String} reason
 */
export const doSendRejectionEmail = (email, displayName, reason) => {
  reason = reason ? reason : "No reason given.";
  return doSendEmail(
    null,
    email,
    null,
    null,
    "AGWSU.org Registration Request Rejected",
    "Hello "
      .concat(displayName)
      .concat(
        ",<br><br>Your registration for AGWSU.org has been <b>rejected</b> for the following reason:<br><br>"
      )
      .concat(reason)
      .concat(
        "<br><br>Please address the reason above on your account and resubmit your approval request.<br>The resubmit button can be found on your account page.<br>Rejected accounts will be deleted after 30 days of inactivity."
      )
  );
};
