/**
 * @fileoverview React Component that displays an avatar, name, title, and email link in a business card like format
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./ContactCard.jss";

import {
  Avatar,
  Badge,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";

/**
 * React Component that displays an avatar, name, title, and email link in a business card like format
 * @implements { Component }
 */
class ContactCard extends Component {
  render() {
    const { classes, avatarImage, email, name, title } = this.props;

    return (
      <Paper className={classes.contactCard}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <a
              href={"mailto:" + email}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Avatar alt="email" id="globalSmallAvatar" variant="square">
                <EmailIcon />
              </Avatar>
            </a>
          }
        >
          <Avatar className={classes.contactItem} src={avatarImage}></Avatar>
        </Badge>
        <div className={classes.contactItem}>
          <Typography align="left" variant="h4">
            {name}
          </Typography>
          <Typography align="left" variant="subtitle1">
            {title}
          </Typography>
        </div>
      </Paper>
    );
  }
}

ContactCard.propTypes = {
  avatarImage: PropTypes.node,
  email: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
};

ContactCard.defaultProps = {
  email: "defaultEmail@email.com",
  name: "Default Name",
  title: "Default Title",
};

export default withStyles(styles)(ContactCard);
