import { ActionType } from "redux-promise-middleware";

export const ANNOUNCEMENTS_CHANGED = "ANNOUNCEMENTS_CHANGED";
export const ANNOUNCEMENT_CREATE = "ANNOUNCEMENT_CREATE";
export const ANNOUNCEMENT_DELETE = "ANNOUNCEMENT_DELETE";
export const ANNOUNCEMENT_EDIT = "ANNOUNCEMENT_EDIT";
export const UPDATE_MAX_LANDING_WEEKS = "UPDATE_MAX_LANDING_WEEKS";

export const LOGIN = "LOGIN";
export const LOGIN_CHANGED = "LOGIN_CHANGED";
export const LOGOUT = "LOGOUT";

export const INIT_USER = "INIT_USER";
export const DELETE_CURRENT_USER = "DELETE_CURRENT_USER";
export const RESUBMIT_USER_REQUEST = "RESUBMIT_USER_REQUEST";
export const SIGNUP = "SIGNUP";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const CURRENT_USER_DB_CHANGED = "CURRENT_USER_DB_CHANGED";
export const SEND_VERIFICATION_EMAIL = "SEND_VERIFICATION_EMAIL";
export const SEND_PASSWORD_RESET_EMAIL = "SEND_PASSWORD_RESET_EMAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_EMAIL_FROM_ACTION_CODE = "GET_EMAIL_FROM_ACTION_CODE";

export const ANNOUNCEMENTS_CONFIG_CHANGED = "ANNOUNCEMENTS_CONFIG_CHANGED";

export const APPROVE_USER = "APPROVE_USER";
export const REJECT_USER = "REJECT_USER";
export const DENY_USER = "DENY_USER";
export const USERS_CHANGED = "USERS_CHANGED";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_DISPLAY_NAME = "UPDATE_DISPLAY_NAME";

export const POST_SNACKBAR_MESSAGE = "POST_SNACKBAR_MESSAGE";

/**
 * Appended _PENDING to action
 * @param {String} action action to make pending
 * @return {String} Pending Action
 */
export const pendingAction = (action) =>
  action.concat("_".concat(ActionType.Pending));

/**
 * Appended _FULFILLED to action
 * @param {String} action action to make pending
 * @return {String} Pending Action
 */
export const fulfilledAction = (action) =>
  action.concat("_".concat(ActionType.Fulfilled));

/**
 * Appended _REJECTED to action
 * @param {String} action action to make pending
 * @return {String} Pending Action
 */
export const rejectedAction = (action) =>
  action.concat("_".concat(ActionType.Rejected));
