/**
 * @fileoverview redux actions
 */
import * as firebaseAuth from "../firebase/firebaseAuth";
import * as firebaseAnnouncements from "../firebase/firebaseAnnouncements";
import * as firebaseUsers from "../firebase/firebaseUsers";
import * as firebaseMail from "../firebase/firebaseMail";
import * as types from "./constants/actionTypes";
import * as routes from "./constants/routes";

import history from "../history";

export const approveUser = (uid, email, displayName) => ({
  type: types.APPROVE_USER,
  payload: Promise.all([
    new Promise((resolve, reject) => {
      firebaseMail
        .doSendApprovalEmail(email, displayName)
        .then(() => resolve())
        .catch((error) => reject(error));
    }),
    new Promise((resolve, reject) => {
      firebaseUsers
        .doApproveUser(uid)
        .then(() => resolve())
        .catch((error) => reject(error));
    }),
  ]),
});

export const rejectUser = (uid, email, displayName, message) => ({
  type: types.REJECT_USER,
  payload: Promise.all([
    new Promise((resolve, reject) => {
      firebaseMail
        .doSendRejectionEmail(email, displayName, message)
        .then(() => resolve())
        .catch((error) => reject(error));
    }),
    new Promise((resolve, reject) => {
      firebaseUsers
        .doRejectUser(uid)
        .then(() => resolve())
        .catch((error) => reject(error));
    }),
  ]),
});

export const initUser = (displayName, email) => ({
  type: types.INIT_USER,
  payload: new Promise((resolve, reject) => {
    firebaseUsers
      .doInitUser(displayName, email)
      .then(() => resolve())
      .catch((error) => reject(error));
  }),
});

/**
 * Delete Current User
 * @param {*} password required to reauthenticate user before deleting account
 * @summary Deletes the user's firestore entry and firebase authentication after reauthentication.
 */
export const deleteCurrentUser = (password) => ({
  type: types.DELETE_CURRENT_USER,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .reauthenticate(password)
      .then(() =>
        firebaseUsers
          .doDeleteCurrentUser()
          .then(() =>
            firebaseAuth.doDeleteCurrentUser(password).then(() => resolve())
          )
      )
      .catch((error) => reject(error));
  }),
});

export const resubmitApprovalRequest = () => ({
  type: types.RESUBMIT_USER_REQUEST,
  payload: new Promise((resolve, reject) => {
    firebaseUsers
      .doResubmitApprovalRequest()
      .then(() => resolve())
      .catch((error) => reject(error));
  }),
});

/**
 * Async Login Action
 * @param {*} email user email
 * @param {*} password user password
 */
export const login = (email, password) => ({
  type: types.LOGIN,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        resolve();
      })
      .catch((err) => reject(err));
  }),
});

/**
 * Async Logout Action
 */
export const logout = () => ({
  type: types.LOGOUT,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doSignOut()
      .then(() => {
        resolve();
      })
      .catch((err) => reject(err));
  }),
});

/**
 * Login changed action.  To be used by firebase observer only
 * @param {String} displayName
 * @param {String} email
 * @param {Boolean} emailVerified
 * @param {String} uid
 */
export const loginChanged = (
  displayName = null,
  email = null,
  emailVerified = null,
  uid = null
) => ({
  type: types.LOGIN_CHANGED,
  payload: {
    displayName: displayName,
    email: email,
    emailVerified: emailVerified,
    uid: uid,
  },
});

export const sendVerificationEmail = () => ({
  type: types.SEND_VERIFICATION_EMAIL,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doSendVerificationEmail()
      .then(() => resolve())
      .catch((error) => reject(error));
  }),
});

/**
 * Signs up a new user using the information provided, then signs out the user
 * @param displayName
 * @param email
 * @param password
 */
export const signUp = (displayName, email, password) => {
  return (dispatch) => {
    const signUpUser = dispatch({
      type: types.SIGNUP,
      payload: new Promise((resolve, reject) => {
        firebaseAuth
          .doCreateUserWithEmailAndPassword(email, password)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }),
    });

    signUpUser
      .then(() => {
        dispatch(updateCurrentUserProfile(displayName));
        dispatch(initUser(displayName, email));
        dispatch(sendVerificationEmail());
      })
      .catch((error) => console.log("Signup Rejected"));
  };
};

/**
 * Changes user profile in firebase authentication service
 * @param {String} name Display name of user
 * @param {String} photoURL User Image URL
 */
export const updateCurrentUserProfile = (name, photoURL = undefined) => ({
  type: types.UPDATE_CURRENT_USER,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doUpdateCurrentUserProfile(name, photoURL)
      .then(() => {
        resolve();
      })
      .catch((error) => reject(error));
  }),
});

/**
 * Updates password for current user
 * @param {String} oldPassword
 * @param {String} newPassword
 */
export const updatePassword = (oldPassword, newPassword) => ({
  type: types.UPDATE_PASSWORD,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doUpdatePassword(oldPassword, newPassword)
      .then(() => resolve())
      .catch((error) => reject(error));
  }),
});

/**
 * Updates displayname for current user
 * @param {String} displayName
 * @param {String} password
 */
export const updateDisplayName = (displayName) => ({
  type: types.UPDATE_DISPLAY_NAME,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doUpdateCurrentUserProfile(displayName)
      .then(() =>
        //if the display name already exists in the display names array, we need to remove it first
        firebaseUsers
          .doRemoveDisplayName(displayName)
          .then(() => firebaseUsers.doAddDisplayName(displayName))
          .then(() => resolve())
      )
      .catch((error) => reject(error));
  }),
});

export const updateEmail = (email, password) => ({
  type: types.UPDATE_EMAIL,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doUpdateEmail(email, password)
      .then(() =>
        //if the email already exists in the emails array, we need to remove it first
        firebaseUsers
          .doRemoveEmail(email)
          .then(() => firebaseUsers.doAddEmail(email))
          .then(() => firebaseUsers.doUpdateEmailVerified(false))
          .then(() => resolve())
      )
      .catch((error) => reject());
  }),
});

/** ANNOUNCEMENTS ACTIONS */

/**
 * Creates a template Announcement with the next day as the date
 */
export const announcementCreate = () => ({
  type: types.ANNOUNCEMENT_CREATE,
  payload: new Promise((resolve, reject) => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    firebaseAnnouncements
      .doCreateAnnouncement(
        "New Announcement",
        "New announcement content.",
        date
      )
      .then(() => resolve())
      .catch((err) => reject(err));
  }),
});

/**
 * Edits the Announcement given
 * @param {string} id
 * @param {string} title
 * @param {string} content
 * @param {Date} date
 */
export const announcementEdit = (id, title, content, date, pin) => ({
  type: types.ANNOUNCEMENT_EDIT,
  payload: new Promise((resolve, reject) => {
    firebaseAnnouncements
      .doEditAnnouncement(id, title, content, date, pin)
      .then(() => resolve())
      .catch((err) => reject(err));
  }),
});

/**
 * Deletes the given announcement
 * @param {string} id
 */
export const announcementDelete = (id) => ({
  type: types.ANNOUNCEMENT_DELETE,
  payload: new Promise((resolve, reject) => {
    firebaseAnnouncements
      .doDeleteAnnouncement(id)
      .then(() => resolve())
      .catch((err) => reject(err));
  }),
});

export const updateMaxLandingWeeks = (weeks) => ({
  type: types.UPDATE_MAX_LANDING_WEEKS,
  payload: new Promise((resolve, reject) => {
    firebaseAnnouncements
      .doSetMaxLandingWeeks(weeks)
      .then(() => resolve())
      .catch((err) => reject(err));
  }),
});

/**
 * Announcements changed action.  To be used by firebase observer only
 * @param {Object} announcements Announcements collection
 */
export const announcementsChanged = (announcements) => ({
  type: types.ANNOUNCEMENTS_CHANGED,
  payload: announcements,
});

/**
 * Announcements config changed action.  To be used by firebase observer only
 * @param {Object} announcementsConfig Announcements config object
 */
export const announcementsConfigChanged = (announcementsConfig) => ({
  type: types.ANNOUNCEMENTS_CONFIG_CHANGED,
  payload: announcementsConfig,
});

export const currentUserDbChanged = (user) => ({
  type: types.CURRENT_USER_DB_CHANGED,
  payload: user,
});

/**
 * Users changed action.  To be used by firebase observer only
 * @param {Object} users users object
 */
export const usersChanged = (users) => ({
  type: types.USERS_CHANGED,
  payload: users,
});

/**
 * Gets user email address from action code
 * @param {string} oobCode from url query parameters
 */
export const getEmailFromActionCode = (oobCode) => ({
  type: types.GET_EMAIL_FROM_ACTION_CODE,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doGetActionCodeInfo(oobCode)
      .then((actionCodeInfo) => resolve(actionCodeInfo.data.email))
      .catch((err) => reject(err));
  }),
});

/**
 * Verifies email with actionCode, updates db to reflect change
 * @param {string} oobCode from url query parameters
 */
export const verifyEmail = (oobCode) => ({
  type: types.VERIFY_EMAIL,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doVerifyEmail(oobCode)
      .then(() =>
        firebaseUsers.doUpdateEmailVerified(true).then(() => {
          history.push({ pathname: routes.LANDING, search: "" });
          resolve();
        })
      )
      .catch((err) => reject(err));
  }),
});

export const resetPassword = (oobCode, newPassword) => ({
  type: types.RESET_PASSWORD,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doPasswordReset(oobCode, newPassword)
      .then(() => {
        history.push({ pathname: routes.LANDING, search: "" });
        resolve();
      })
      .catch((err) => reject(err));
  }),
});

export const sendPasswordResetEmail = (email) => ({
  type: types.SEND_PASSWORD_RESET_EMAIL,
  payload: new Promise((resolve, reject) => {
    firebaseAuth
      .doSendPasswordResetEmail(email)
      .then(() => resolve())
      .catch((err) => reject(err));
  }),
});

export const postSnackbarMessage = (message) => ({
  type: types.POST_SNACKBAR_MESSAGE,
  payload: message,
});
