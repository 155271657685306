/**
 * @fileoverview Methods for interfacing with firebase users
 * @exports { doInitUser }
 */

import firebase from "firebase/app";
import { auth, db } from "./firebaseInit";
import store from "../store/store";
import { isAdmin } from "../store/selectors";

import { usersChanged, currentUserDbChanged } from "../store/actions";

let users = db.collection("users");
let currentUserDbListener = null,
  usersListener = null;

/**
 * Initializes user in db for current user
 */
export const doInitUser = (displayName, email) => {
  return users.doc(auth.currentUser.uid).set({
    displayNames: [displayName],
    emails: [email],
    admin: false,
    approved: false,
  });
};

export const doDeleteCurrentUser = () => {
  return users.doc(auth.currentUser.uid).delete();
};

export const doApproveUser = (uid) => {
  return users.doc(uid).update({
    approved: true,
  });
};

export const doRejectUser = (uid) => {
  return users.doc(uid).update({
    rejected: true,
  });
};

export const doResubmitApprovalRequest = () => {
  return users.doc(auth.currentUser.uid).update({
    rejected: false,
  });
};

export const doAddEmail = (email) => {
  return users.doc(auth.currentUser.uid).update({
    emails: firebase.firestore.FieldValue.arrayUnion(email),
  });
};

export const doRemoveEmail = (email) => {
  return users.doc(auth.currentUser.uid).update({
    emails: firebase.firestore.FieldValue.arrayRemove(email),
  });
};

export const doUpdateEmailVerified = (verified) => {
  return users.doc(auth.currentUser.uid).update({
    emailVerified: verified,
  });
};

export const doAddDisplayName = (displayName) => {
  return users.doc(auth.currentUser.uid).update({
    displayNames: firebase.firestore.FieldValue.arrayUnion(displayName),
  });
};

export const doRemoveDisplayName = (displayName) => {
  return users.doc(auth.currentUser.uid).update({
    displayNames: firebase.firestore.FieldValue.arrayRemove(displayName),
  });
};

/**
 * Attach listener for firebase current user changes
 */
export const attachCurrentUserListener = () => {
  if (!auth.currentUser) {
    return;
  }
  currentUserDbListener = users.doc(auth.currentUser.uid).onSnapshot(
    (docSnapshot) => {
      if (docSnapshot.data())
        store.dispatch(currentUserDbChanged(docSnapshot.data()));
    },
    (error) => {
      console.log("TODO: current User observer error");
    }
  );
};

/**
 * Detach listener for firebase current user changes
 */
export const detachCurrentUserListener = () => {
  if (currentUserDbListener) currentUserDbListener();
};

/**
 * Attach listener for firebase users changes
 */
export const attachUsersListener = () => {
  if (!isAdmin) {
    return;
  }
  usersListener = users.onSnapshot(
    (collectionSnapshot) => {
      let collection = [];
      collectionSnapshot.forEach((document) => {
        collection.push({ uid: document.id, ...document.data() });
      });
      store.dispatch(usersChanged(collection));
    },
    (error) => {
      console.log("TODO: users observer error");
    }
  );
};

export const detachUsersListener = () => {
  if (usersListener) usersListener();
};
