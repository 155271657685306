/**
 * @fileoverview Reducer for any announcement actions
 */
import * as actions from "../constants/actionTypes";

const actionPending = (state) => ({
  ...state,
  pendingAction: true,
});

const actionFulfilled = (state) => ({
  ...state,
  pendingAction: false,
});

const actionRejected = (state, action) => ({
  ...state,
  pendingAction: false,
  messages: [...state.messages, action.payload.message],
});

const announcementsChanged = (state, action) => ({
  ...state,
  announcements: action.payload,
});

const announcementsConfigChanged = (state, action) => ({
  ...state,
  announcementsConfig: action.payload,
});

const announcementCreated = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "New Announcement Created"],
});

const announcementEdited = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Announcement Saved"],
});

const announcementDeleted = (state, action) => ({
  ...actionFulfilled(state, action),
  messages: [...state.messages, "Announcement Deleted"],
});

let handlers = {};
handlers[actions.pendingAction(actions.ANNOUNCEMENT_CREATE)] = actionPending;
handlers[actions.pendingAction(actions.ANNOUNCEMENT_DELETE)] = actionPending;
handlers[actions.pendingAction(actions.ANNOUNCEMENT_EDIT)] = actionPending;
handlers[actions.fulfilledAction(actions.ANNOUNCEMENT_CREATE)] =
  announcementCreated;
handlers[actions.fulfilledAction(actions.ANNOUNCEMENT_DELETE)] =
  announcementDeleted;
handlers[actions.fulfilledAction(actions.ANNOUNCEMENT_EDIT)] =
  announcementEdited;
handlers[actions.rejectedAction(actions.ANNOUNCEMENT_CREATE)] = actionRejected;
handlers[actions.rejectedAction(actions.ANNOUNCEMENT_DELETE)] = actionRejected;
handlers[actions.rejectedAction(actions.ANNOUNCEMENT_EDIT)] = actionRejected;
handlers[actions.rejectedAction(actions.UPDATE_MAX_LANDING_WEEKS)] =
  actionRejected;
handlers[actions.ANNOUNCEMENTS_CHANGED] = announcementsChanged;
handlers[actions.ANNOUNCEMENTS_CONFIG_CHANGED] = announcementsConfigChanged;

const announcementsReducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export default announcementsReducer;
