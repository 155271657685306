/**
 * @fileoverview React Component for Confirmation Dialog
 */

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Input,
  InputLabel,
  TextField,
  withStyles,
} from "@material-ui/core";

import styles from "./ConfirmDialog.jss";

/**
 * A Confirmation Dialog
 * @implements {Component}
 */
class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { responseText: "" };
  }

  handleResponseTextChange = (event) => {
    this.setState({ responseText: event.target.value });
  };

  render() {
    const {
      cancelButtonText,
      confirmButtonText,
      fullScreen,
      handleConfirm,
      handleCancel,
      inputField,
      message,
      open,
      password,
      textAreaLabel,
      title,
      textArea,
    } = this.props;
    const { responseText } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => handleCancel()}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item>{message}</Grid>
            {inputField && !textArea && (
              <Grid item>
                <InputLabel htmlFor="custom-response">
                  {textAreaLabel}
                </InputLabel>
                <Input
                  id="custom-response"
                  fullWidth
                  type={password ? "password" : "text"}
                  onChange={this.handleResponseTextChange}
                  rows={4}
                  value={responseText}
                  variant="outlined"
                />
              </Grid>
            )}
            {textArea && !inputField && (
              <Grid item>
                <TextField
                  id="custom-response"
                  fullWidth
                  label={textAreaLabel}
                  multiline
                  onChange={this.handleResponseTextChange}
                  rows={4}
                  value={responseText}
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleCancel(responseText)}
          >
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleConfirm(responseText)}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  fullScreen: PropTypes.bool,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool,
  textArea: PropTypes.bool,
  inputField: PropTypes.bool,
  password: PropTypes.bool,
  textAreaLabel: PropTypes.string,
  title: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  cancelButtonText: "Cancel",
  confirmButtonText: "Okay",
  fullScreen: false,
  message: "Default Message",
  open: false,
  textArea: false,
  inputField: false,
  password: false,
  textAreaLabel: "Response",
  title: "Confirm",
};

export default withStyles(styles)(ConfirmationDialog);
